import React from 'react';
import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { injectIntl, intlShape } from '../../util/reactIntl';
import { camelize } from '../../util/string';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchFeaturedListings, saveToWhishlist, ASSET_NAME } from './LandingPage.duck';

import PageBuilder from '../../containers/PageBuilder/PageBuilder';
import {
  SectionHero,
  TopPicks,
  WorkingStation,
  SubtitlePage,
  EarnMoney,
  YouPick,
  SimpleSlider
} from '../../components'

import FallbackPage from './FallbackPage';

export const LandingPageComponent = props => {
  const { 
    pageAssetsData, 
    inProgress, 
    listings, 
    history,
    updateInProgress,
    updateProfileError,
    onFavoriteListing,
    isAuthenticated,
    currentUser,
  } = props;

  const topPicksWithListings = () => (
    <TopPicks 
      listings={listings} 
      history={history}
      updateInProgress={updateInProgress}
      updateProfileError={updateProfileError}
      onFavoriteListing={onFavoriteListing}
      isAuthenticated={isAuthenticated}
      currentUser={currentUser}
    />
  )

  const sectionHero = () => (
    <SectionHero
      updateInProgress={updateInProgress}
      updateProfileError={updateProfileError}
      isAuthenticated={isAuthenticated}
      currentUser={currentUser}
      {...pageAssetsData?.[camelize(ASSET_NAME)]?.data.sections.find(section => section.sectionName === 'hero' || section.sectionId === 'hero')}
    />
  )

  return (
    <PageBuilder
      pageAssetsData={pageAssetsData?.[camelize(ASSET_NAME)]?.data}
      inProgress={inProgress}
      fallbackPage={<FallbackPage />}
      options={{
        sectionComponents: {
          hero: {component: sectionHero},
          "our-top-picks": {component: topPicksWithListings},
          "work-station": {component: WorkingStation},
          'value': {component: SubtitlePage},
          'earn-money': {component: EarnMoney},
          "you-pick": {component: YouPick },
          "section-community": { component: SimpleSlider },
        },
      }}
    />
  );
};

LandingPageComponent.propTypes = {
  // from injectIntl
  intl: intlShape.isRequired,
  pageAssetsData: object,
  inProgress: bool,
};

const mapStateToProps = state => {
  const {
    featuredListings,
    updateInProgress,
    updateProfileError,
  } = state.LandingPage;

  const { isAuthenticated } = state.Auth;
  const { currentUser } = state.user;

  const { pageAssetsData, inProgress } = state.hostedAssets || {};

  const listings = featuredListings ? getMarketplaceEntities(state, featuredListings) : [];

  return { 
    listings, 
    pageAssetsData, 
    inProgress,
    updateInProgress,
    updateProfileError,
    isAuthenticated,
    currentUser,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchFeaturedListings: (listingIdOne, listingIdTwo, listingIdThree) => dispatch(fetchFeaturedListings(listingIdOne, listingIdTwo, listingIdThree)),
  onFavoriteListing: (listingId) => dispatch(saveToWhishlist(listingId)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(LandingPageComponent);

export default LandingPage;
